import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import FormField from '../../components/form/field';
import EmptySection from '../../components/chrome/empty';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getUsers } from '../../actions/user';
import { getHistory } from '../../actions/history';
import { getItems } from '../../actions/hub';
import { getBrands } from '../../actions/brand';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

const History = props => {
	
	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const hub = useSelector(state => state.hub);
	const history = useSelector(state => state.history);
	const brand = useSelector(state => state.brand);
	
	const [selectedItem, setSelectedItem] = useState(query.get('item'));
	const [selectedUser, setSelectedUser] = useState(query.get('user'));
	const [selectedDateFrom, setSelectedDateFrom] = useState(query.get('from') ? moment(query.get('from')) : moment().subtract(1, 'week'));
	const [selectedDateTo, setSelectedDateTo] = useState(query.get('to') ? moment(query.get('to')) : moment());
	
	const refreshHistory = () => {
		
		let options = {
			from: moment(selectedDateFrom).format('YYYY-MM-DD'),
			to: moment(selectedDateTo).format('YYYY-MM-DD')
		}
		
		if(selectedItem){
			options.item = selectedItem;
		}
		
		if(selectedUser){
			options.user = selectedUser;
		}
		
		dispatch(getHistory(options));
	}
	
	useEffect(() => {
		dispatch(getUsers());
		dispatch(getItems());
		dispatch(getBrands());
		refreshHistory();
	}, [dispatch]);
	
	useEffect(() => {
		refreshHistory();
	}, [selectedItem,selectedUser,selectedDateFrom,selectedDateTo]);
	
	if(!user.users || !hub.items || !brand.brands){
		
		return (
			<Loading />
		);
	}	
	
	const List = () => {
		
		if(_.isEmpty(history.history)){ 
			return (
				<EmptySection
					title="No History"
					icon="fal fa-search"
					description={"There is no historic data to display within the current search criteria"}
					fullHeight={true}
				/>
			);
			
		}else{
						
			return(
				<div className="table-responsive">
					<Table 
						className="data-table" 
						id="table"
						sortable={[
						    'date',
						    'user',
						    'item',
						    'brand'
						]}
						defaultSort={{column: 'name', direction: 'asc'}}
						hideFilterInput
						itemsPerPage={30} 
						pageButtonLimit={5}
					>
						<Thead>
							<Th column="date" className="sorting">Date</Th>
							<Th column="user" className="sorting">User</Th>
							<Th column="source" className="sorting">Source</Th>
							<Th column="item" className="sorting">Item</Th>
							<Th column="brand" className="sorting">Brand</Th>
						</Thead>
       
				        {_.map(history.history, (event, key) => {
					        
					        let name = 'Unknown';
					        let item = 'Unknown';
					        let branding = 'Unknown';
							let source = 'TeamBase';
					        
					        if(user.users[event.user_id]){
						        name = `${user.users[event.user_id].forename} ${user.users[event.user_id].surname}`;
					        }
					        
					        if(hub.items[event.hub_item_id]){
						        item = hub.items[event.hub_item_id].title;
					        }
					        
					        if(brand.brands[event.brand_id]){
						        branding = brand.brands[event.brand_id].name;
					        }
							
							switch(event.source){
								
								case 'wellbeing':
									source = 'Hapori Wellbeing';
									break;
							}
		
							return (
								<Tr key={key}>
						            <Td column="date" value={event.datetime.date}>
						                {moment(event.datetime.date).format('DD/MM/YYYY HH:mm:ss')}
									</Td>
						            <Td column="user" value={name}>
					                	{/*<Link to={`/users/${event.user_id}`}>*/}
					                		{name}
										{/*</Link>*/}
						            </Td>
									<Td column="source" value={source}>
										{source}
									</Td>
						            <Td column="item">
										{/*<Link to={`/history/item/${event.hub_item_id}`}>*/}
					                		{item}
					                	{/*</Link>*/}
						            </Td>
						            <Td column="brand">
						            	{branding}
						            </Td>
						        </Tr>
						    );
						})}
					</Table>
				</div>
			);	
		}
	}

	return (
		<div className="dashboard">
		    
		    <div id="page-header">
				<h2>
					Viewing History
				</h2>
			</div>
		    
		    <div className="panel">
	    		<div className="content">	    			
					<div className="row">
						<div className="col col-4 col-sm-12 col-md-4">
							<FormField
								name="date"
								className="mb-30"
								type="daterange"
								noControl={true}
								startDate={selectedDateFrom}
								endDate={selectedDateTo}
								onDatesChange={({ startDate, endDate }) => { 
									setSelectedDateFrom(startDate); 
									setSelectedDateTo(endDate);
								}}
								isOutsideRange={() => false}
							/>
						</div>
						<div className="col col-4 col-sm-12 col-md-4">
							<FormField
								name="user"
								placeholder="Filter users"
								className="mb-30"
								isClearable={true}
								type="suggest"
								noControl={true}
								simpleValue
								sortorder={true}
								options={_.map(user.users, (user, key) => {
									return(
										{
											label: `${user.forename} ${user.surname}`,
											value: user.id
										}
									);
								})}
								selected={selectedUser}
								onChangeFunc={(selected) => { 
									setSelectedUser(selected ? selected.value : false);
								}}
							/>
						</div>
						<div className="col col-4 col-sm-12 col-md-4">
							<FormField
								name="user"
								placeholder="Filter items"
								className="mb-30"
								isClearable={true}
								type="suggest"
								noControl={true}
								simpleValue
								sortorder={true}
								options={_.map(hub.items, (item, key) => {
									return(
										{
											label: item.title,
											value: item.id
										}
									);
								})}
								selected={selectedItem}
								onChangeFunc={(selected) => { 
									setSelectedItem(selected ? selected.value : false);
								}}
							/>
						</div>
					</div>	
	    			<List />
	    		</div>
	    	</div>
		    
		</div>
	);
}

export default History;