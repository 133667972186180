import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import FormField from '../../components/form/field';
import Button from '../../components/chrome/button';
import Loading from '../../components/chrome/loading';
import { getUser, saveUserGroups, saveUserAdmin, deleteUser } from '../../actions/user';
import { getBrand } from '../../actions/brand';
import { getGroups } from '../../actions/group';
import _ from 'lodash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const UsersGroups = props => {

	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const { handleSubmit } = props;
	const { user_id, brand_id } = useParams();
	
	if(!user_id || !brand_id){
		props.history.push(props.parentPath);
	}

	const account = useSelector(state => state.account);
	const group = useSelector(state => state.group);
	const brand = useSelector(state => state.brand);
	const user = useSelector(state => state.user);

	const [admin, setAdmin] = useState(false);
	const [selectedGroups, setSelectedGroups] = useState([]);
	
	useEffect(() => {
		dispatch(getUser(user_id));
		dispatch(getBrand(brand_id));
		dispatch(getGroups(brand_id));
	}, []);	
	
	useEffect(() => {
		
		if(user.users && user.users[user_id] && user.users[user_id].groups){
			setSelectedGroups(user.users[user_id].groups);
						
			if(user.users[user_id].roles == 'ROLE_SUPERADMIN' || user.users[user_id].admin.includes(parseFloat(brand_id))){
				setAdmin(true);
			}
		}
	}, [user]);
	
	if(!user.users || !user.users[user_id] || !brand.brands || !brand.brands[brand_id] || !group.groups){
		
		return (
			<Loading />
		);
	}
	
	const Submit = (values, dispatch, props) => {

		let posting = {
			brand: brand_id, 
			groups: selectedGroups
		}
						
		dispatch(saveUserGroups(
			user_id, 
			posting
		)).then((id) => {
			
			if(account.role == 'ROLE_SUPERADMIN' && user.users[user_id].roles !== 'ROLE_SUPERADMIN'){
				
				posting = {
					brand: brand_id, 
					admin: admin
				}
				
				dispatch(saveUserAdmin(
					user_id, 
					posting
				)).then((id) => {
					props.history.push(props.parentPath);
				});
				
			}else{
				props.history.push(props.parentPath);
			}
		});
	}
	
	return (
		<div>
			<div className="header">
				<h3>
					{brand.brands[brand_id].name} Access for {user.users[user_id].forename} {user.users[user_id].surname}
				</h3>
			</div>			

			<form onSubmit={handleSubmit(Submit)}>		
			
				<label className="label-main">Restricted Access</label>	
			
				{!_.isEmpty(group.groups[brand_id]) && 
					<FormField
						name="groups"
						placeholder="Select groups(s) or leave blank for all open access"
						type="suggest"
						noControl={true}
						sortorder={true}
						isMulti
						options={_.map(group.groups[brand_id], (value, key) => {
							return(
								{
									label: value.name,
									value: value.id
								}
							);
						})}
						selected={selectedGroups}
						onChangeFunc={(selected) => { 
							setSelectedGroups(_.map(selected, (item) => {
								return item.value;
							}));
						}}
					/>
				||
					<div>
						There are no groups within this brand, so full access is currently granted.
					</div>
				}
				
				{account.role == 'ROLE_SUPERADMIN' && 
				
					<React.Fragment>
					
						<label className="label-main mt-40">Admin Access</label>	
					
						{user.users[user_id].roles == 'ROLE_SUPERADMIN' && 
							<div>
								User is a super admin, so inherits full admin on all brands.
							</div>
						||
							<Field 
								className="mt-10"
								name="message" 
								type="checkbox" 
								options={[
									{
										label: 'Set as admin user',
										value: 1
									}
								]}
								checked={admin}
								onChangeFunc={(event) => { 
									setAdmin(event.target.checked);
								}}
								component={FormField} 
							/>
						}
					</React.Fragment>
				}
				
				<div className="cta">
					
					{(!_.isEmpty(group.groups[brand_id]) || account.role == 'ROLE_SUPERADMIN') && 
						
						<Button
							{...props}
							label="Save"
						/>
					}
					
					<Button
						label="Revoke Access"
						type="button"
						className="warning"
						onClick={() => {
							
							MySwal.fire({
								icon: 'question',
								title: 'Delete',
								text: `Are you sure you wish to revoke ${user.users[user_id].forename} ${user.users[user_id].surname}'s access to ${brand.brands[brand_id].name}?`,
								showCancelButton: true,
								customClass: {
									confirmButton: 'btn warning mr-10',
									cancelButton: 'btn'
								},
								buttonsStyling: false
							}).then((result) => {
								
								if(result.isConfirmed){
									dispatch(deleteUser(
										user_id,
										brand_id
									)).then((brands) => {
										
										if(brands.length == 0){
											props.history.push(`/users?brand=${brand_id}`);
										}else{
											props.history.push(props.parentPath);
										}
									});
								}
							});
						}}
					/>
				</div>
			</form>
		</div>
	);
}

const validate = values => {
	
	const errors = {}
	
	return errors
}

export default reduxForm({
	form: 'groups',
	validate
})(UsersGroups);