import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import FormField from '../../components/form/field';
import EmptySection from '../../components/chrome/empty';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getBrand } from '../../actions/brand';
import _ from 'lodash';
import moment from 'moment';
import InfoBox from '../../components/chrome/info_box';

const BrandsView = props => {
	
	const { id } = useParams();
	
	if(!id){
		props.history.push('/brands');
	}
	
	const dispatch = useDispatch();
	const brand = useSelector(state => state.brand);
	
	useEffect(() => {
		console.log('useEffect', 'BrandsView')
		dispatch(getBrand(id));
	}, []);
	
	if(!brand.brands || !brand.brands[id]){
		
		return (
			<Loading />
		);
	}
	
	const details = brand.brands[id];
		
	const onCodeClick = (id) => {
		props.history.push(`/brands/${details.id}/code/${details.id}/${id}`);
	}
	
	return (
		<div>
		    
		    <div id="page-header">
				<h2>
					{details.name}
				</h2>
				
				<div id="actions">
					<Button
						label="New Hub Item"
						url={`/brands/${details.id}/item?brand=${details.id}`}
						split={[
							{
								label: 'Send Notification',
								url: `/brands/${details.id}/compose?brand=${details.id}`
							},
							{
								label: 'New Signup Code',
								url: `/brands/${details.id}/code/${details.id}`
							}	
						]}
					/>
				</div>
			</div>
			
			<div className="row">
				<div className="col-12 col-sm-6 col-md-4">
					<div className="panel">
			    		<div className="header">
			    			<i className="fal fa-building"></i>
			    			<h3>Profile</h3>
			    			<p>
			    				 Brand profile and details.
			    			</p>
			    		</div>
			    		<div className="content">
    		
							<div className="mb-15">
								<label className="label-main">Landing Page</label>
								<div>
									<a href={`https://${details.domain}`} target="_blank">{details.domain}</a>
								</div>
							</div>
							
							<div className="mb-15">
								<label className="label-main">Created</label>
								<div>
									{moment(details.created.date).format('DD/MM/YYYY')}
								</div>
							</div>
							
							<div className="mb-15">
								<label className="label-main">Hapori Wellbeing</label>
								<div>
									{details.wellbeing ? 'Enabled' : 'Disabled'}
								</div>
							</div>
																					
							<div className="mb-15">
								<label className="label-main">
									Signup Codes
									
									<Link to={`/brands/${details.id}/code/${details.id}`}>
										Add new
									</Link>
								</label>
								
								{details.codes.length > 0 && 
									<div className="table-responsive">
										<Table 
											className="data-table row-click" 
											id="table"
											sortable={[
												'code',
												'groups'
											]}
											defaultSort={{column: 'code', direction: 'asc'}}
											hideFilterInput
										>
											<Thead>
												<Th column="code" className="sorting">Code</Th>
												<Th column="groups" className="sorting">Groups</Th>										
											</Thead>
											{_.map(details.codes, (code, key) => {
												
												return (
													<Tr key={`code_${key}`}
														onClick={() => onCodeClick(code.id) } 
														className="clickabale"
													>
														<Td column="code">
															{code.code}
														</Td>
														<Td column="groups">
															{code.groups.length}
														</Td>
													</Tr>
												);
											})}
										</Table>
									</div>
								|| 
									<React.Fragment>
										There are currently no sign up codes!
									</React.Fragment>
								}
							</div>

							{/*<Link to={`/brands/${details.id}/brand/${details.id}`} className="btn full-width mb-10">
								Edit Profile
							</Link>*/}
						</div>
					</div>
				</div>
				<div className="col-12 col-sm-6 col-md-8">	
					<div className="panel">
			    		<div className="header">
			    			<i className="fal fa-info-circle"></i>
			    			<h3>Overview</h3>
			    			<p>
			    				Quickly see the state of play for {details.name}.
			    			</p>
			    		</div>
			    		<div className="content">
								
							<div className="row pt-3">								
								<div className="col-md-4 pb-md-0">
									<InfoBox 
										title="Hub Items" 
										title_sub={`Last item ${details.items.last ? moment(details.items.last).format('DD/MM/YYYY') : 'NA'}`}
										value={details.items.total}
										cta={{
											label: 'View Items',
											url: `/items/${details.id}`
										}}
									/>
								</div>
								
								<div className="col-md-4 pb-md-0">
									<InfoBox 
										title="Active Alerts" 
										title_sub={`Last alert ${details.alerts.last ? moment(details.alerts.last).format('DD/MM/YYYY') : 'NA'}`}
										value={details.alerts.total}
										cta={{
											label: 'View Alerts',
											url: `/alerts?brand=${details.id}`
										}}
									/>
								</div>
								<div className="col-md-4 pb-md-0">
									<InfoBox 
										title="Total Users" 
										title_sub={`Last signup ${details.users.last ? moment(details.users.last).format('DD/MM/YYYY') : 'NA'}`}
										value={details.users.total}
										cta={{
											label: 'View Users',
											url: `/users?brand=${details.id}`
										}}
									/>
								</div>
							</div>
						</div>
					</div>					
				</div>
			</div>	
		</div>
	);
}

export default BrandsView;