import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import FormField from '../../components/form/field';
import Button from '../../components/chrome/button';
import Loading from '../../components/chrome/loading';
import { getBrands } from '../../actions/brand';
import { getItem, saveItem, getCategories, deleteItem } from '../../actions/hub';
import _ from 'lodash';
import { imageResize, imageUrl } from '../../helpers/s3';
import { getAssets } from '../../actions/asset';
import AssetPicker from '../assets/picker';
import ReactPlayer from 'react-player';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const ItemEdit = props => {

	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const { handleSubmit } = props;
	const { id } = useParams();

	const asset = useSelector(state => state.asset);
	const brand = useSelector(state => state.brand);
	const hub = useSelector(state => state.hub);
	
	const [selectedBrand, setSelectedBrand] = useState(query.get('brand') ? [parseFloat(query.get('brand'))] : []);
	const [selectedCategory, setSelectedCategory] = useState((query.get('brand') && query.get('category')) ? { [query.get('brand')]: query.get('category') } : {});
	const [image, setImage] = useState(false);
	const [video, setVideo] = useState(false);
	const [download, setDownload] = useState(false);
	const [link, setLink] = useState(false);
	const [notify, setNotify] = useState(false);
	
	useEffect(() => {
		dispatch(getAssets());
		dispatch(getBrands());
		dispatch(getCategories());
		
		if(id){
			dispatch(getItem(id));
		}
	}, []);	
	
	useEffect(() => {
		
		if(id){
			
			const item = hub.items[id];
			const data = JSON.parse(item.data);			
			
			props.change('title', item.title);
			props.change('description', item.description);
			
			let assigned = [];
			let categories = {};
			
			if(item.assigned){
				
				_.forEach(item.assigned, (value, brand_id) => {
					assigned.push(parseFloat(brand_id));
					categories[brand_id] = value[0];
				})
			}

			setSelectedCategory(categories);
			setSelectedBrand(assigned);
			
			if(item.asset_image_id){
				setImage(item.asset_image_id);
			}
			
			if(item.asset_video_id){
				setVideo(item.asset_video_id);
			}
			
			if(item.asset_download_id){
				setDownload(item.asset_download_id);
				
				if(data && data.download && data.download.cta){
					props.change('download_cta', data.download.cta);
				}
			}
			
			if(data && data.link){
				setLink(true);
				props.change('link_url', data.link.url);
				
				if(data.link.cta){
					props.change('link_cta', data.link.cta);
				}
				
				if(data.link.title){
					props.change('link_title', data.link.title);
				}
			}	
		}
	}, [hub]);	
	
	if(!asset.files || (id && (!hub.items || !hub.items[id])) || !brand.brands || !hub.categories){
		
		return (
			<Loading />
		);
	}
	
	const Submit = (values, dispatch, props) => {
				
		if(!image){
			alert('Please select an image')
			return false;
		}else if(!selectedBrand || selectedBrand.length == 0){
			
			if(_.size(brand.brands) > 1){
				alert('Please select at least 1 brand')
				return false;
			}
		}
		
		let categories = [];
		let proceed = true;
		
		if(_.size(brand.brands) > 1){
			
			_.forEach(selectedBrand, (brand_id, key) => {
				
				if(selectedCategory[brand_id]){
					categories.push(selectedCategory[brand_id]);
				}else{
					proceed = false;
					alert(`Please select a category for ${brand.brands[brand_id].name}`)
					return false;
				}
			});
		}else{
			
			const singleBrandId = Object.values(brand.brands)[0].id;
			
			if(selectedCategory[singleBrandId]){
				categories.push(selectedCategory[singleBrandId]);
			}else{
				proceed = false;
				alert(`Please select a category`)
				return false;
			}
		}
		
		
		if(categories.length > 0 && proceed){
	
			let posting = {
				title: values.title,
				description: values.description,
				category: categories,
				//brands: selectedBrand,
				image: {
					asset_id: image
				}
			}
					
			if(video){
				posting.video = {
					asset_id: video
				}
			}
		
			if(download){
				posting.download = {
					asset_id: download,
					cta: values.download_cta
				}
			}
			
			if(link){
				posting.link = {
					url: values.link_url,
					cta: values.link_cta,
					title: values.link_title
				}
			}
					
			dispatch(saveItem(
				id ? id : false, 
				posting
			)).then((id) => {
				
				if(notify){
					props.history.push(`${props.parentPath}/compose?item=${id}`);
				}else{
					props.history.push(props.parentPath);
				}
			});
		}
	}

	const Asset = ({ id }) => {
				
		if(asset.files && asset.files[id]){
		
			if(asset.files[id].type == 'video'){
				return (
					<div className="video-container">
						<ReactPlayer 
							url={imageUrl(asset.files[id].filename)} 
							controls={true}
							width='100%'
							height='100%'
							className='react-player'
							config={{ file: { 
								attributes: {
									controlsList: 'nodownload'
								}
							}}}
						/>
					</div>
				)
			}else if(asset.files[id].type == 'image'){
				return (
					<img src={imageResize(asset.files[id].filename, 500)} width="100%" />
				);
			}else{
				return (
					<React.Fragment>
						{asset.files[id].title}
					</React.Fragment>
				);
			}
		}
		
		return null;
	}	
		
	const organiseCategories = (brand_id) => {
		
		let ret = [];
		
		const loopCategories = (brand_id, level, id) => {
		
			let options = _.sortBy(_.filter(hub.categories[brand_id], { parent_id: id }), ['sortorder']);
			
			_.forEach(options, (child, key) => {
				
				let label = child.title;
				
				if(level > 0){
					label = '- '.repeat(level) + label;
				}
				
				ret.push({
					value: child.id,
					label: label
				});
				
				options[key].children = loopCategories(brand_id, level+1, child.id);
			});
			
			return options;
		}
		
		loopCategories(brand_id, 0, null);
		
		return ret;
	}
	
	const BrandList = () => {
		
		const Categories = ({ id }) => {
									
			return (
				<FormField
					type="suggest"
					name={`category[${id}]`}
					noControl={true}
					options={organiseCategories(id)}
					selected={selectedCategory[id] ? selectedCategory[id] : false}
					onChangeFunc={(selected) => { 
						let current = selectedCategory;
						current[id] = selected.value;
						setSelectedCategory(_.clone(current));
					}}
				/>
			)
		}

		if(_.size(brand.brands) == 1){
			
			return (
				<React.Fragment>
					<label className="label-main mt-40">Category</label>
					<Categories id={Object.values(brand.brands)[0].id} />			
				</React.Fragment>
			)
			
		}else{
			
			return (
				
				<React.Fragment>
				
					<label className="label-main mt-40">Brands</label>
					
					{_.map(brand.brands, (brand, key) => {
											
						return (
							<div className="row">
								<div className="col col-12 col-sm-4 col-md-3">
									<FormField
										name={`brand[${brand.id}]`}
										type="checkbox"
										options={[{
											value: '1',
											label: brand.name
										}]}
										checked={_.indexOf(selectedBrand, brand.id) >= 0 ? true : false}
										onChangeFunc={(event) => { 
											
											let current = selectedBrand;
																
											if(event.target.checked){
												current.push(brand.id);
											}else{
												current = _.pull(current, brand.id);
											}
							
											setSelectedBrand(_.uniq(current));
										}}
									/>						
								</div>
								<div className="col col-12 col-sm-8 col-md-9">
									{_.indexOf(selectedBrand, brand.id) >= 0 && 
										<Categories id={brand.id} />		
									}						
								</div>
							</div>
						)
					})}
				</React.Fragment>
			);
		}
	}
	
	return (
		<div>
			<div className="header">
				<h3>
					{id ? 'Edit Item' : 'Add Item'}
				</h3>
			</div>

			<form onSubmit={handleSubmit(Submit)}>			
								
				<Field 
					name="title" 
					type="text" 
					label="Title" 
					component={FormField} 
				/>
				
				<Field 
					name="description" 
					type="textarea" 
					label="Content" 
					component={FormField} 
				/>				
				
				<div className="row">
					
					<div className="col col-12 col-sm-6 col-md-3 mb-30">
						<label className="label-main">Image</label>
								
						{!image &&
							<p>No image selected.</p>
						|| 
							<Asset id={image} />
						}
							
						<AssetPicker
							button={{
								label: image ? 'Change' : 'Choose',
								className: 'secondary medium  mt-10'
							}}
							max="1"
							types={['image']}
							onSelect={(files) => {										
		
								setImage(files[0]);
							}}
							selected={image ? [image] : []} 
						/>

					</div>
					
					<div className="col col-12 col-sm-6 col-md-3 mb-30">
						<label className="label-main">Video</label>
								
						{!video &&
							<p>No video selected.</p>
						|| 
							<Asset id={video} />
						}
							
						<AssetPicker
							button={{
								label: video ? 'Change' : 'Choose',
								className: 'secondary medium  mt-10'
							}}
							max="1"
							types={['video']}
							onSelect={(files) => {										
		
								setVideo(files[0]);
							}}
							selected={video ? [video] : []} 
						/>
					</div>
					
					<div className="col col-12 col-sm-6 col-md-3 mb-30">
						<label className="label-main">Download</label>
						
						{!download &&
							<p>No download selected.</p>
						|| 
							<Asset id={download} />
						}
							
						<AssetPicker
							button={{
								label: download ? 'Change' : 'Choose',
								className: 'secondary medium  mt-10'
							}}
							max="1"
							onSelect={(files) => {										
								setDownload(files[0]);
							}}
							selected={download ? [video] : []} 
						/>
						
						{download &&
							<Field 
								name="download_cta" 
								type="text" 
								label="CTA" 
								component={FormField} 
							/>
						}
					</div>
					
					<div className="col col-12 col-sm-6 col-md-3 mb-30">						
						<Field 
							name="link_url" 
							type="url" 
							label="Link" 
							placeholder="Leave blank for no link"
							component={FormField} 
							onChangeFunc={(event) => { setLink(event.target.value !== '' ? true : false) }}
						/>						
						
						{link && 
							<React.Fragment>
								<Field 
									name="link_cta" 
									type="text" 
									label="CTA" 
									component={FormField} 
								/>
								
								<Field 
									name="link_title" 
									type="text" 
									label="Page Title" 
									component={FormField} 
								/>
							</React.Fragment>
						}
						
					</div>
				</div>
				
				
				
				<BrandList />
								
				<Field 
					label="Send Notification?"
					className="mt-40"
					name="message" 
					type="checkbox" 
					options={[
						{
							label: 'Yes, compose notification on save',
							value: 1
						}
					]}
					checked={notify}
					onChangeFunc={(event) => { 
						setNotify(event.target.checked);
					}}
					component={FormField} 
				/>
				
				<div className="cta">
					<Button
						{...props}
						label="Save Item"
					/>
					
					{id && 
						<Button
							label="Delete"
							type="button"
							className="warning"
							onClick={() => {
								
								MySwal.fire({
									icon: 'question',
									title: 'Delete',
									text: 'Are you sure you wish to delete this item?',
									showCancelButton: true,
									customClass: {
										confirmButton: 'btn warning mr-10',
										cancelButton: 'btn'
									},
									buttonsStyling: false
								}).then((result) => {
									
									if(result.isConfirmed){
										dispatch(deleteItem(
											id
										)).then((id) => {
											props.history.push(props.parentPath);
										});
									}
								});
							}}
						/>
					}
				</div>
			</form>
		</div>
	);
}

const validate = values => {
	
	const errors = {}

	if(!values.title) {
		errors.title = 'Required'
	}
	
	if(!values.description) {
		errors.description = 'Required'
	}
	
	if(!values.category) {
		errors.category = 'Required'
	}
	
	if(!values.assigned) {
		errors.assigned = 'Required'
	}
	
	return errors
}

export default reduxForm({
	form: 'item',
	validate
})(ItemEdit);