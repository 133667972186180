import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import FormField from '../../components/form/field';
import Button from '../../components/chrome/button';
import { accountLogin } from '../../actions/account';

const Submit = (values, dispatch, props) => {

	dispatch(accountLogin({ 
		email: values.email, 
		password: values.password 
	})).then(response => {
				
		if(response){
			props.history.push('/');
		}
	});
}

const Login = props => {
		
	const { handleSubmit } = props;
	
	return (
		<div id="simple">
		
			<a href="/">
				<img src={`/images/logo.png?v=${process.env.CACHE_DIR}`} alt="My Coaching Platform" id="logo" />
			</a>

			<h1>
				Account login
			</h1>
			
			<p className="intro">
				Please enter your login credentials below to access your account.  
			</p>
			
			<form onSubmit={handleSubmit}>			
				
				<Field 
					name="email" 
					type="email" 
					label="Email" 
					component={FormField} 
				/>
				
				<Field 
					name="password" 
					type="password" 
					label="Password" 
					component={FormField} 
				/>
				
				<Button
					{...props}
					label="Login"
				/>
				
			</form>
		</div>
	);
}

const validate = values => {
	
	const errors = {}

	if(!values.email) {
		errors.email = 'Required'
	}else{
		
		var validator = require("email-validator");

		if(!validator.validate(values.email)){
			errors.email = 'Enter a valid email address!';
		}
	}
	
	if(!values.password) {
		errors.password = 'Required'
	}
	
	return errors
}

export default reduxForm({
	form: 'login',
	validate,
	onSubmit: Submit
})(Login);