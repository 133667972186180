import { userConstants } from '../constants/user';
import _ from 'lodash';

export default function(state = {}, action) {
	
	let users;
	
	switch (action.type) {

		case userConstants.GET_USERS:

			return { 
				...state, 
				users: _.mapKeys(action.payload.data, 'id')
			};	
			
		case userConstants.GET_USER:
		case userConstants.SAVE_USER:

			users = state.users ? state.users : {};
			
			users[action.payload.data.id] = action.payload.data;
			
			return { 
				...state, 
				users: users
			};
		
		case userConstants.SAVE_GROUPS:
		
			users = state.users ? state.users : {};
			
			if(users[action.payload.data.id]){
				users[action.payload.data.id].groups =  action.payload.data;
			}
			
			return { 
				...state, 
				users: users
			};
			
		default:
			return state;
	}
}