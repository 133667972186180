import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import FormField from '../../components/form/field';
import EmptySection from '../../components/chrome/empty';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getBrands } from '../../actions/brand';
import _ from 'lodash';
import moment from 'moment';

const Brands = props => {

	const dispatch = useDispatch();
	const brand = useSelector(state => state.brand);
	
	useEffect(() => {
		dispatch(getBrands());
	}, []);
	
	useEffect(() => {

		if(brand.brands && _.size(brand.brands) == 1){
			onRowClick(Object.values(brand.brands)[0].id);
		}
	}, [brand]);
	
	if(!brand.brands){
		
		return (
			<Loading />
		);
	}	
	
	const onRowClick = (id) => {
		props.history.push(`/brands/${id}`);
	}
	
	const List = () => {
		
		if(_.isEmpty(brand.brands)){ 
			return (
				<EmptySection
					title="No Brands"
					icon="fal fa-building"
					description="There are currently no brands on the system!"
					fullHeight={true}
				/>
			);
			
		}else{
			return(
				<div className="table-responsive">
					<Table 
						className="data-table row-click" 
						id="table"
						sortable={[
						    'name',
						    'users_count',
						    'users_last',
						    'items_count',
						    'items_last'
						]}
						defaultSort={{column: 'name', direction: 'asc'}}
						hideFilterInput
						itemsPerPage={30} 
						pageButtonLimit={5}
					>
						<Thead>
							<Th column="name" className="sorting">Brand Name</Th>
							<Th column="wellbeing" className="sorting">Hapori Wellbeing</Th>
							<Th column="users_count" className="sorting">Total Users</Th>
							<Th column="users_last" className="sorting">Last Signup</Th>
							<Th column="items_count" className="sorting">Total Items</Th>
							<Th column="items_last" className="sorting">Last Item</Th>
						</Thead>
       
				        {_.map(brand.brands, brand => {
		
							return (
								<Tr key={brand.id} 
								onClick={() => onRowClick(brand.id) } 
								className="clickabale">
						            <Td column="name" value={brand.name}>
						                <strong>{brand.name}</strong>
						            </Td>
									<Td column="wellbeing">
										{brand.wellbeing ? 'Yes' : 'No'}
									</Td>
						            <Td column="users_count">
						                {brand.users.total}
						            </Td>
						            <Td column="users_last" value={brand.items.last}>						                
						                {brand.users.last ? moment(brand.users.last).format('DD/MM/YYYY') : 'NA'}
						            </Td>
						            <Td column="items_count">
						                {brand.items.total}
						            </Td>
						            <Td column="items_last" value={brand.items.last}>
						                {brand.users.last ? moment(brand.users.last).format('DD/MM/YYYY') : 'NA'}
						            </Td>
						        </Tr>
						    );
						})}
					</Table>
				</div>
			);	
		}
	}

	return (
		<div className="dashboard">
		    
		    <div id="page-header">
				<h2>
					Brands
				</h2>
				
				{/*<div id="actions">
					<Button
						label="New Brand"
						url="/brands/brand"
					/>
				</div>*/}
			</div>
		    
		    <div className="panel">
	    		<div className="content">
	    			<List />
	    		</div>
	    	</div>
		</div>
	);
}

export default Brands;