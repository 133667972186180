import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import FormField from '../../components/form/field';
import EmptySection from '../../components/chrome/empty';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getUsers } from '../../actions/user';
import { getBrands } from '../../actions/brand';
import _ from 'lodash';
import moment from 'moment';

const Users = props => {
	
	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const brand = useSelector(state => state.brand);
	
	const [selectedBrand, setSelectedBrand] = useState(query.get('brand'));
	
	useEffect(() => {
		console.log('useEffect', 'Users')
		dispatch(getUsers());
		dispatch(getBrands());
	}, [dispatch]);
	
	if(!user.users){
		
		return (
			<Loading />
		);
	}	
	
	const onRowClick = (id) => {
		props.history.push(`/users/${id}`);
	}
	
	const List = () => {
		
		let users = [];		
				
		if(selectedBrand){
			
			_.forEach(user.users, (user) => {
				
				if(user.brands.includes(parseFloat(selectedBrand))){
					users.push(user);
				}
			});
			
		}else{
			users = user.users;
		}
			
		if(_.isEmpty(users)){ 
			return (
				<EmptySection
					title="No Users"
					icon="fal fa-user-friends"
					description={`${selectedBrand ? 'There are currently no users assigned to this brand' : 'There are currently no users on the system!'}`}
					fullHeight={true}
				/>
			);
			
		}else{
						
			return(
				<div className="table-responsive">
					<Table 
						className="data-table row-click" 
						id="table"
						sortable={[
						    'name',
						    'brands_count',
						    'created',
						    'view_last'
						]}
						defaultSort={{column: 'name', direction: 'asc'}}
						hideFilterInput
						itemsPerPage={30} 
						pageButtonLimit={5}
					>
						<Thead>
							<Th column="name" className="sorting">Name</Th>
							<Th column="brands_count" className="sorting">Total Brands</Th>
							<Th column="created" className="sorting">Joined</Th>
							<Th column="view_last" className="sorting">Last Item Viewed</Th>
						</Thead>
       
				        {_.map(users, user => {
		
							return (
								<Tr key={user.id} 
									onClick={() => onRowClick(user.id) } 
									className="clickabale"
								>
						            <Td column="name" value={`${user.forename} ${user.surname}`}>
						                <strong>{user.forename} {user.surname}</strong>
						            </Td>
						            <Td column="brands_count">
										{user.brands.length}
						            </Td>
						            <Td column="created" value={user.created.date}>
										{moment(user.created.date).format('DD/MM/YYYY HH:mm')}
						            </Td>
						            <Td column="view_last" value={user.views.last}>
										{user.views.last ? moment(user.views.last).format('DD/MM/YYYY HH:mm') : 'NA'}
						            </Td>
						        </Tr>
						    );
						})}
					</Table>
				</div>
			);	
		}
	}
	
	const Search = () => {
		
		if(!_.isEmpty(user.users) && _.size(brand.brands) > 1){ 

			return(
				<FormField
					name="brand"
					placeholder="Filter users from a specific brand"
					className="mb-30"
					isClearable={true}
					type="suggest"
					noControl={true}
					simpleValue
					sortorder={true}
					options={_.map(brand.brands, (brand, key) => {
						return(
							{
								label: brand.name,
								value: brand.id
							}
						);
					})}
					selected={selectedBrand}
					onChangeFunc={(selected) => { 
						setSelectedBrand(selected ? selected.value : false) 
					}}
				/>
			);	
		}
		
		return null;
	}

	return (
		<div className="dashboard">
		    
		    <div id="page-header">
				<h2>
					Users
				</h2>
			</div>
		    
		    <div className="panel">
	    		<div className="content">
	    			<Search />
	    			<List />
	    		</div>
	    	</div>
		    
		</div>
	);
}

export default Users;