import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import FormField from '../../components/form/field';
import Button from '../../components/chrome/button';
import Loading from '../../components/chrome/loading';
import { getUser, saveUser } from '../../actions/user';
import _ from 'lodash';

const UserProfile = props => {

	const dispatch = useDispatch();
	const { handleSubmit } = props;
	const { user_id } = useParams();
	
	if(!user_id){
		props.history.push(props.parentPath);
	}

	const user = useSelector(state => state.user);

	useEffect(() => {
		dispatch(getUser(user_id));
	}, []);	
	
	useEffect(() => {
		
		if(user.users && user.users[user_id]){
			props.change('forename', user.users[user_id].forename);
			props.change('surname', user.users[user_id].surname);
			props.change('email', user.users[user_id].email);
			
		}
	}, [user]);
	
	if(!user.users || !user.users[user_id]){
		
		return (
			<Loading />
		);
	}
	
	const Submit = (values, dispatch, props) => {

		dispatch(saveUser(
			user_id, 
			values
		)).then((id) => {
			props.history.push(props.parentPath);
		});
	}

	return (
		<div>
			<div className="header">
				<h3>
					Edit User
				</h3>
			</div>
			
			<form onSubmit={handleSubmit(Submit)}>			
			
				<Field 
					name="forename" 
					type="text" 
					label="Forename" 
					component={FormField} 
				/>
				
				<Field 
					name="surname" 
					type="text" 
					label="Surname" 
					component={FormField} 
				/>
				
				<Field 
					name="email" 
					type="email" 
					label="Email Address" 
					component={FormField} 
				/>
			
				<div className="cta">
					<Button
						{...props}
						label="Save"
					/>
				</div>
			</form>
		</div>
	);
}

const validate = values => {
	
	const errors = {}
	
	if(!values.forename) {
		errors.forename = 'Required'
	}
	
	if(!values.surname) {
		errors.surname = 'Required'
	}
	
	if(!values.email) {
		errors.email = 'Required'
	}
	
	return errors
}

export default reduxForm({
	form: 'profile',
	validate
})(UserProfile);