import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getBrands } from '../../actions/brand';
import { getItem } from '../../actions/hub';
import _ from 'lodash';
import { imageResize, imageUrl } from '../../helpers/s3';
import ReactPlayer from 'react-player';

const ItemView = props => {
	
	const { id } = useParams();
		
	if(!id){
		props.history.push(props.parentPath);
	}
	
	const dispatch = useDispatch();
	const brand = useSelector(state => state.brand);
	const hub = useSelector(state => state.hub);
	
	const [assigned, setAssigned] = useState(false);
	const [assignedOther, setAssignedOther] = useState(false);
	
	useEffect(() => {
		dispatch(getBrands());
		dispatch(getItem(id));
	}, [dispatch]);
	
	if(!hub.items || !hub.items[id] || !brand.brands){
		
		return (
			<Loading />
		);
	}
	
	const details = hub.items[id];
	const data = JSON.parse(details.data);
	
	const Assigned = () => {
				
		let others = 0;
		
		const ret = _.map(details.assigned, (categoeies, brand_id) => {
																						
			if(brand.brands && brand.brands[brand_id]){
				
				setAssigned(true);
				
				return (
					<li key={brand_id}>
						<Link to={`/brands/${brand_id}`}>
							{brand.brands[brand_id].title}
						</Link>
					</li>
				);
			}else{
				++others;
				setAssignedOther(true);
				return null;
			}
		})
											
		return (
			<React.Fragment>
				<ul>
					{ret}
				</ul>
				
				{others > 0 && 
					<div style={{ marginTop: '5px', fontSize: '11px' }}>
						+ {others} other{others > 1 ? 's' : ''} which you do not have access to.
					</div>
				}
			</React.Fragment>
		);								
	}
	
	return (
		<div>
			<div className="header">
				<h3>
					{details.title}
				</h3>
			</div>

			<div className="row">
					
				<div className="col col-12 col-sm-8 col-md-9">
				
					<div>
						<label className="label-main">Content</label>
						<div>
							{details.description}
						</div>
					</div>
					
				
					<div className="row mt-40">
					
						<div className="col col-12 col-sm-4">
					
							<label className="label-main">Image</label>
							<img src={imageResize(details.asset_image, 1500)} width="100%" />
						</div>
					
						{details.asset_video && 
							<div className="col col-12 col-sm-4">
								<label className="label-main">Video</label>
								<div className="video-container">
									<ReactPlayer 
										url={imageUrl(details.asset_video)} 
										controls={true}
										width='100%'
										height='100%'
										className='react-player'
										config={{ file: { 
											attributes: {
												controlsList: 'nodownload'
											}
										}}}
									/>
								</div>
							</div>
						}
						
						{(details.asset_download || (data && data.link)) &&
							<div className="col col-12 col-sm-4">
								{details.asset_download && 
									<React.Fragment>
										<label className="label-main">Download</label>
										<a href={imageUrl(details.asset_download)} target="_blank" className="btn secondary">{data.download && data.download.cta ? data.download.cta : 'Download'}</a>
									</React.Fragment>
								}
								
								{data && data.link && 
									<React.Fragment>
										<label className="label-main mt-40">Link</label>
										<a href={data.link.url} target="_blank" className="btn secondary">{data.link.cta ? data.link.cta : 'Visit'}</a>
									</React.Fragment>						
								}
							</div>
						}
					</div>
				</div>
				
				<div className="col col-12 col-sm-4 col-md-3">
					<div className="mb-15">
						<label className="label-main">Assigned</label>
						<Assigned /> 
					</div>
					
					<div className="cta">
						{!assignedOther && 
							<Button
								label="Edit"
								url={`${props.parentPath}/item/${details.id}/edit`}
							/>
						}
						
						<Link 
							to={`${props.parentPath}/compose?item=${details.id}`}
							className="btn secondary"
						>
							Notify
						</Link>	
						<Link 
							to={`/history?item=${details.id}`}
							className="btn secondary"
						>
							History
						</Link>					
		    		</div>
		    	</div>
			</div>
		</div>
	);
}

export default ItemView;